@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Raleway', system-ui, sans-serif;
  }
}

@media (min-width: 1024px) {
  body {
    max-height: 100vh !important;
    overflow-y: hidden;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.light {
  --page-bg: #ececec;
  --component-bg: #ffffff;
  --chevronBtn-bg: #212121;
  --chevron-color: #ececec;
  --border-color: #61626d;
  --searchIcon-bg: #eeeeee;
  --text-color: #212121;
}

.dark {
  --page-bg: #224a53;
  --component-bg: #152229;
  --chevronBtn-bg: #928471;
  --chevron-color: #ffff;
  --border-color: #61626d;
  --searchIcon-bg: #202123;
  --text-color: #f4f4f4;
}

li::marker {
  color: var(--text-color) !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #a5a4a4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;

  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3a3a3a;

  /* background: #202123; */
}

.w-96 {
  width: 20rem;
}
